$avatar-size: 40px;

.flow-comments-container {
  .flow-comments-container__inner {
    height: calc(100vh - 265px);

    .flow-comments-list {
      max-height: calc(100% - 243px);
      overflow-y: auto;

      .flow-comment-history-container {
        align-items: center;
        min-height: 60px;
        height: 100%;
        padding-right: 20px;
      }

      .flow-comment-history-container--mobile {
        padding: 0;
      }

      .flow-comment-history__avatar {
        height: $avatar-size;

        .avatar {
          top: 0 !important;
          left: 0 !important;

          p {
            line-height: $avatar-size;
            width: $avatar-size;
            height: $avatar-size;
          }
        }
      }

      .lead-line {
        border-left: solid 1px #ddd;
        min-height: 100%;
        margin-left: 20px;
      }

      .lead-line.top {
        padding-bottom: 12.5px;
      }

      .lead-line.bottom {
        padding-top: 17.5px;
      }

      .lead-line-hide {
        border: none;
      }

      .flow-comment-history__comment-container {
        width: 100%;
        min-height: 60px;
        padding: 10px;
        margin: 2.5px 0 2.5px 30px;
        border: solid 1px #e6e6e6;
        border-radius: 4px;
        background-color: #fff;

        .flow-comment-history__file-list {
          margin-bottom: 0px;
          display: flex;
          flex-wrap: wrap;

          li {
            margin: 5px 20px 0 0;
          }
        }
      }

      .flow-comment-history__comment-container--mobile {
        margin-left: 0;
      }

      .flow-comment-history__comment-header {
         display: flex;
         justify-content: space-between;
        font-weight: 600;
        padding-bottom: 5px;

        .flow-model-version {
          text-align: right;
          white-space: nowrap;
          display: flex;
        }
      }

      .flow-comment-history__comment-content {
        text-align: justify;
      }
    }

    .flow-add-comment-container-inner {
      margin-top: 20px;
    }
  }
}
