.ngdialog-content {
  .title {
    overflow-wrap: break-word;
  }
}

.ngdialog-narrow .ngdialog-content {
  width: 23% !important;
  min-width: 400px !important;
}

.ngdialog-normal .ngdialog-content {
  width: 33% !important;
  min-width: 600px !important;
}

@media (max-width: 600px) {
  .ngdialog-normal .ngdialog-content {
    min-width: 90vw !important;
    width: 90vw !important;
  }
}

.ngdialog-wide .ngdialog-content {
  width: 43% !important;
  min-width: 600px !important;
}

.dialog-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > button {
    margin-left: 15px;
  }
}

.mb-25 {
  margin-bottom: 25px;
}

.sub-label {
  display: block;
  color: #666;
  font-size: 11px;
  margin-top: -8px;
}

.inline-list {
  li {
    float: left;
  }
}

.btn-text-only {
  font-weight: 500;
  color: #888;
  background: 0 0;
  border: none;
  box-shadow: none;
  padding-right: 15px;
  height: 36px;
  line-height: 36px;

  &:hover,
  &:focus {
    background: 0 0;
    border: none;
    box-shadow: none;
  }

  &:hover {
    color: #2196f3;
  }
}

/* styles specific to the PUBLiSH dialog */
.ngdialog.ngdialog-theme-plain.ngdialog-publish .ngdialog-content {
  width: 650px;
}

.pad-50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.publish-dialog {
  font-family: 'Open Sans', sans-serif;

  .card .content {
    padding: 0 20px 20px 20px;
  }
}
