﻿#inTurnFadingTextG {
  margin: auto;
}

.inTurnFadingTextG {
  color: rgb(0, 0, 0);
  font-size: 22px;
  color: #888;
  text-decoration: none;
  font-weight: normal;
  font-style: normal;
  float: left;
  animation-name: bounce_inTurnFadingTextG;
  -o-animation-name: bounce_inTurnFadingTextG;
  -ms-animation-name: bounce_inTurnFadingTextG;
  -webkit-animation-name: bounce_inTurnFadingTextG;
  -moz-animation-name: bounce_inTurnFadingTextG;
  animation-duration: 2.09s;
  -o-animation-duration: 2.09s;
  -ms-animation-duration: 2.09s;
  -webkit-animation-duration: 2.09s;
  -moz-animation-duration: 2.09s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}

#inTurnFadingTextG_1 {
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}
#inTurnFadingTextG_2 {
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}
#inTurnFadingTextG_3 {
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}
#inTurnFadingTextG_4 {
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}
#inTurnFadingTextG_5 {
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}
#inTurnFadingTextG_6 {
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}
#inTurnFadingTextG_7 {
  animation-delay: 1.64s;
  -o-animation-delay: 1.64s;
  -ms-animation-delay: 1.64s;
  -webkit-animation-delay: 1.64s;
  -moz-animation-delay: 1.64s;
}

@keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-o-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-ms-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-webkit-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}

@-moz-keyframes bounce_inTurnFadingTextG {
  0% {
    color: rgb(0, 0, 0);
  }

  100% {
    color: rgb(255, 255, 255);
  }
}
