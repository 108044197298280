.flow-comments-container {
  justify-content: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-top: none;
}

.flow-comments-container__inner {
  width: 100%;
}

.flow-comments-container--mobile {
  .flow-comments-container {
    padding: 10px;
  }
}
