//Color palette
$color-border: #e6e6e6;
$color-darkgrey: #efebe9;
$color-flow-content-secondary: #97a8b1;
$color-highlight-blue: #039be5;
$color-primary-blue: #42a5f5;
$color-tab-border-hover: #5c6dc4;
$color-action-green: #4caf50;
$color-text-error: red;
$color-text-primary: #35384d;
$color-text-warning: orangered;
$color-white: #ffffff;
$default-background-color: #fafafa;
$flow-icon-square-bg-color: #378687;
$height-brand-logo: 26px;
$max-width-contents: 1200px;
$placeholder-color: #a9a9a9;
$width-brand-logo: 115px;
$width-side-menu-default: 60px;
$width-toggle-menu-button: 18px;

$color-grey: #909090;
$color-grey-lighter-20: scale-color($color-grey, $lightness: 20%);
$color-grey-lighter-50: scale-color($color-grey, $lightness: 50%);
$color-grey-lighter-80: scale-color($color-grey, $lightness: 80%);
$color-grey-lighter-90: scale-color($color-grey, $lightness: 90%);

$color-deep-forest: #264653;
$color-deep-forest-lighter-20: scale-color($color-deep-forest, $lightness: 20%);
$color-deep-forest-lighter-50: scale-color($color-deep-forest, $lightness: 50%);
$color-deep-forest-lighter-80: scale-color($color-deep-forest, $lightness: 80%);

$color-grape: #977cbf;
$color-grape-lighter-20: scale-color($color-grape, $lightness: 20%);
$color-grape-lighter-50: scale-color($color-grape, $lightness: 50%);
$color-grape-lighter-80: scale-color($color-grape, $lightness: 80%);

$color-mauve: #c77e8f;
$color-mauve-lighter-20: scale-color($color-mauve, $lightness: 20%);
$color-mauve-lighter-50: scale-color($color-mauve, $lightness: 50%);
$color-mauve-lighter-80: scale-color($color-mauve, $lightness: 80%);

$color-redish: #e36040;
$color-redish-lighter-20: scale-color($color-redish, $lightness: 20%);
$color-redish-lighter-50: scale-color($color-redish, $lightness: 50%);
$color-redish-lighter-80: scale-color($color-redish, $lightness: 80%);

$color-burnt-orange: #ec8151;
$color-burnt-orange-lighter-20: scale-color(
  $color-burnt-orange,
  $lightness: 20%
);
$color-burnt-orange-lighter-50: scale-color(
  $color-burnt-orange,
  $lightness: 50%
);
$color-burnt-orange-lighter-80: scale-color(
  $color-burnt-orange,
  $lightness: 80%
);

$color-mandarin: #f4a261;
$color-mandarin-lighter-20: scale-color($color-mandarin, $lightness: 20%);
$color-mandarin-lighter-50: scale-color($color-mandarin, $lightness: 50%);
$color-mandarin-lighter-80: scale-color($color-mandarin, $lightness: 80%);

$color-mustard: #e9c46a;
$color-mustard-lighter-20: scale-color($color-mustard, $lightness: 20%);
$color-mustard-lighter-50: scale-color($color-mustard, $lightness: 50%);
$color-mustard-lighter-80: scale-color($color-mustard, $lightness: 80%);

$color-forrest: #8ab17d;
$color-forrest-lighter-20: scale-color($color-forrest, $lightness: 20%);
$color-forrest-lighter-50: scale-color($color-forrest, $lightness: 50%);
$color-forrest-lighter-80: scale-color($color-forrest, $lightness: 80%);

$color-verde: #2a9d8f;
$color-verde-lighter-20: scale-color($color-verde, $lightness: 20%);
$color-verde-lighter-50: scale-color($color-verde, $lightness: 50%);
$color-verde-lighter-80: scale-color($color-verde, $lightness: 80%);

$color-legacy-blue: #4982c4;
$color-legacy-blue-lighter-20: scale-color($color-legacy-blue, $lightness: 20%);
$color-legacy-blue-lighter-50: scale-color($color-legacy-blue, $lightness: 50%);
$color-legacy-blue-lighter-80: scale-color($color-legacy-blue, $lightness: 80%);
