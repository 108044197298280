﻿.autocomplete-items {
  position: relative;
  border: 1px solid #d4d4d4;
  z-index: 99999;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}
.auto-completed-selected {
  display: inline-block;
  vertical-align: middle;
  padding: 2px 4px;
  word-break: break-all;
  margin: 4px;
  background-color: #fff;
  border-radius: 10px;
  color: #333;
  position: relative;
  overflow: hidden;
  border: 1px solid #dfdfdf;
}
