﻿$color-grey: #fafafa;
$color-orangeRed: #ff4500;
$color-lightblue: #e4f0f6;
$color-border: #e6e6e6;
$color-text-primary: #35384d;

.notification-icon {
  margin-top: -5px;
}

#userNotifications {
  position: relative;
}

.notification-icon--active {
  &:after {
    content: '';
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid white;
    z-index: 1;
    position: absolute;
    top: 45px;
    margin-left: 1px;
  }
}

.notification-round-badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: 10px;
  right: 6px;
  font-family: 'Open Sans', sans-serif;
  font-size: 9.1px;
  background: $color-orangeRed;
  color: white;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  border-radius: 50%;
}

.notification {
  position: absolute;
  background-color: #fafafa;
  width: 450px;
  border: 1px solid #e6e6e6;
  top: 50px;
  line-height: 45px;
  border-radius: 6px;
  right: 0px;
  cursor: default;
  color: #35384d;
  max-height: 75vh;
  overflow-y: auto;
  word-wrap: break-word;
  margin-right: 20px;
  text-align: center;

  .heading {
    font-weight: 600;
    font-size: 15px;
    background-color: white;
    line-height: 40px;
    height: 40px;
    padding: 0px 0 0px 10px;
  }

  .heading_noNotifications {
    font-weight: 400;
    background-color: white;
    font-size: 13px;
    background-color: $color-grey;
    text-align: center;
  }

  .comment {
    margin: 5px 0 0 0;
    padding: 10px;
    line-height: 20px;

    .comment_header {
      max-width: 73% !important;
      text-align: left;
      font-weight: 600;
    }
  }

  .discard {
    color: #808080;
    top: -18px;
    font-size: 15px;
    position: relative;
    cursor: pointer;
    height: 0px !important;
    opacity: 0.85;
  }

  .btn__notification {
    line-height: 25px;
  }

  .unread {
    background-color: rgba(228, 240, 246, 1);
  }

  .read {
    background-color: white;
  }
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .notification {
    margin-right: 0 !important;
    width: 100% !important;
    max-height: 100vh !important;

    .discard {
      top: 0px !important;
    }
  }
}

.unreadNotificationLink {
  text-align: center;
  width: 100%;
  position: absolute;
  cursor: pointer;
  background-color: white;
  line-height: 40px !important;
  right: 0;
}

.unreadNotificationLink {
  text-align: center;
  width: 100%;
  position: absolute;
  cursor: pointer;
  background-color: white;
  right: 0;
}

.unread_left_border {
  border-left: 4px solid #0079bf;
}

.animation.ng-leave {
  -webkit-transition: 1s;
}

.animation.ng-leave {
  opacity: 1;
}

.animation.ng-leave.ng-leave-active {
  opacity: 0;
}

.noNotificaitons_bottom {
  text-align: center;
  background-color: white;
}
