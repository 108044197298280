﻿$block-header-avatar-width: 30px;

.avatar-container {
  position: relative;
  height: 0px;
}

.avatar {
  position: absolute;
  border-radius: 50%;
  height: $block-header-avatar-width;
  line-height: $block-header-avatar-width;
  width: $block-header-avatar-width;
  top: 5px;
  left: 0px;
  display: block;

  p {
    margin: 0;
    color: white;
    text-align: center;
    text-transform: uppercase;
    line-height: $block-header-avatar-width;
  }

  img {
    height: $block-header-avatar-width;
    width: $block-header-avatar-width;
    border-radius: 50%;
  }
}
