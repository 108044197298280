﻿$row-height: 100px;
$cell-width: 150px;
$cell-active-colour: #efefef;
$table-header-color: #daecfb;

.flow-table-builder {
  position: relative;
  max-width: 600px;
}

.flow-table-builder-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: $row-height;

  .btn-add {
    width: 60px;
    min-height: 90px;
    background-color: #2196f3;
    padding: 5px;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;

    span {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  .active-cell {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

    .cell-body {
      position: relative;
      background: $cell-active-colour;
      z-index: 3;
      margin: -2px;
      margin-bottom: -10px;
      border-bottom: 1px solid $cell-active-colour;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);

      &:after {
        margin-bottom: 2px solid $cell-active-colour;
        height: 25px;
        width: 100%;
        background: $cell-active-colour;
        content: '';
        position: absolute;
        left: 0;
      }
    }
  }
}

.flow-table-builder-cell {
  display: flex;
  min-width: $cell-width;
  max-width: 200px;
  flex-direction: column;
  border: 1px solid #ccc;

  &:hover {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
    cursor: pointer;
  }

  .cell-header {
    white-space: nowrap;
    border-bottom: none;
    background: #efefef;
    padding-left: 10px;
    height: 30px;
    line-height: 30px;
    position: relative;

    .fa-trash-can {
      position: absolute;
      top: 5px;
      right: 5px;
      color: white;
      background-color: #e57373;
      padding: 2px 4px;
      border-radius: 3px;
      line-height: 16px;
      font-size: 0.875em;
    }
  }

  .cell-body {
    background: #fafafa;
    padding: 5px;

    input {
      pointer-events: none;
    }
  }

  .cell-file {
    min-height: 52px;
    text-align: center;

    .btn {
      background-color: #ddd !important;
      margin-top: 10px;
      color: #35384d;

      &:hover {
        color: #fff;
      }
    }
  }

  .cell-date {
    padding-top: 8px;
    min-height: 52px;
  }
}
/* dont show double border when multiple cells on a row */
flow-table-builder-cell + flow-table-builder-cell {
  .flow-table-builder-cell {
    border-left: 1px solid transparent;
  }
}

.flow-table-builder-row-error {
  .error-msg {
    color: red !important;
    text-align: left;
  }
}

.flow-table-builder-cell-editor {
  position: sticky;
  padding: 20px;
  width: 50%;
  background: $cell-active-colour;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  z-index: 2;
  margin-top: 10px;
  overflow-y: auto;
  max-height: 350px;

  .error-msg {
    color: red !important;
    text-align: left;
  }

  .flow-table-builder-cell-formula input {
    border-color: #9e9e9e !important;
  }
}
