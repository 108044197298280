﻿$row-height: 100px;
$cell-width: 150px;
$cell-active-colour: #efefef;

.flow-table-viewer {
  table {
  }
  table,
  tr,
  th,
  td {
    border: 0;
  }

  th {
    border: 0 !important;
  }

  tr {
    border-bottom: 1px solid #ddd !important;
  }

  tr:last-child {
    border-bottom: 0 !important;
  }

  td {
    padding: 5px 10px;
  }

  pre {
    //ensure that text in <pre> is left aligned
    font-family: inherit;
    white-space: normal;
  }

  input {
    //we need to use an input to display formatted currency using ng-currency.
    //these styles ensure the input does not look like one
    //and cannot be interacted with
    border: none;
    background-color: #fafafa;
    pointer-events: none;
  }

  .header-row {
    background-color: #eee;
    border: 0 !important;

    tr,
    th,
    td {
      padding: 5px;
      padding-left: 15px;
    }
  }

  .sum-row td {
    border-top: 2px solid #aaa;
    background: #efefef;
    max-height: 15px;

    input[type='text']:disabled {
      background-color: #efefef !important;
      color: #35384d !important;
      border-bottom: none;
      margin: 0;
      max-height: 15px;
    }
  }
}
