﻿.portal-profile {
  max-width: 1200px;
  .card {
    margin: 0 10px 10px 0;
  }
}
.blue-hover {
  &:hover {
    color: #2196f3 !important;
  }
}

.remove-image:hover img {
  opacity: 0.5;
}

div.remove-image:hover button {
  display: block;
}

div.remove-image button.delete {
  left: 90%;
}

.remove-image button.delete {
  display: none;
  vertical-align: central;
}

.remove-image button {
  position: absolute;
  display: none;
  color: red;
}

.user-avatar-image.remove-image {
  position: relative;

  .delete {
    position: absolute;
    right: -25px;
    top: -10px;
  }
}
