﻿$table-header-color: #daecfb;
$btn-blue: #42a5f5;
$btn-grey: #aaa;

//when displayed in a dialog in the modeler
.ngdialog-content {
  .flow-table-runner {
    .btn,
    .btn-remove {
      pointer-events: none;
    }

    .btn {
      background-color: #ddd;
    }
  }
}

.flow-table-runner {
  margin-bottom: 10px;

  .btn {
    background-color: $btn-blue;
  }

  .btn-container {
    cursor: pointer;
  }

  .btn-remove {
    color: $btn-grey;
    font-size: 20px;

    &:hover {
      color: $btn-blue;
    }
  }

  table {
    table-layout: fixed;
    thead {
      th:last-of-type {
        width: 30px;
      }
    }
    tbody {
      td:last-of-type {
        text-align: end;
      }
    }
  }

  .table-header {
    background-color: $table-header-color;

    th {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.5px;
      font-weight: 600;
    }

    .optional-text {
      color: #9e9e9e;
    }

    .formula-field {
      // same as lookup-field class
      color: #97a8b1;
      font-weight: normal;
    }

    .lookup-field2 {
      // same as lookup-field class
      color: #97a8b1;
      font-weight: normal;
    }
  }

  //k-picker-wrap is dynamically inserted by kendo date directive
  .cell-date .k-datepicker {
    width: 100%;
  }

  .cell-dropdown .k-dropdown {
    width: 100%;
  }

  .cell-file {
    .file-upload-field label {
      font-size: 11px !important;
    }

    .btn {
      background-color: #ddd !important;
      color: #35384d !important;
      cursor: pointer;

      &:hover {
        color: #fff;
      }
    }
  }

  th {
    padding: 10px;
    padding-left: 15px;
  }

  td {
    padding: 0;
  }

  td:last-child {
    //the last column shows the remove row button . But it is not always displayed
    //to avoid the table shrinking when the button appears, we give the column a min width.
    min-width: 20px;
  }

  input {
    padding-top: 0;
    //inactive inputs are shown without a border
    border-bottom: 0;
    /*&.error {
            border-bottom: 1px solid red
        }*/
  }

  textarea {
    border: none;
  }

  label.title {
    font-weight: 600;
    font-size: 15px;
    display: block;
    padding-bottom: 5px;
  }

  tr:focus {
    input {
      padding-top: 0;
      //inactive inputs are shown without a border
      border-bottom: 1px solid #9e9e9e;
    }
  }

  .error-list {
    color: red;
    margin-top: 10px;
    text-align: right;
    margin-right: 125px;
  }

  .sum-row td {
    background: #f6f6f6;
    color: #35384d;
    border-radius: 0;
    border-right: 2px solid white;
    padding-left: 11px;

    &:nth-last-of-type(2) {
      border-right: none;
    }

    input[type='text']:disabled {
      background-color: #f6f6f6 !important;
      color: #35384d !important;
      border-bottom: none;
      margin-bottom: 0;
    }
  }

  label.btn-blue {
    background-color: #42a5f5 !important;
    color: #fff;
  }

  .file-upload-field {
    font-size: 13px;
  }

  .file-upload-field .file {
    margin: 5px;
    padding: 5px 10px;
    background: #efefef;
    color: #00f;
    font-weight: 600;
  }

  .file-upload-field .file span {
    color: #999;
    margin-left: 3px;
    margin-right: 15px;
    font-weight: 400;
  }

  .file-upload-field .file a {
    color: #2196f3;
  }

  .file-upload-field .file .fa-xmark {
    cursor: pointer;
    color: #2196f3;
    float: right;
    line-height: 20px;
  }

  .no-border {
    border: none;
  }
}

.table-file-upload-container {
  padding: 12px;

  .table-fileUpload {
    padding: 12px;
    font-weight: 300 !important;
    height: 42px !important;
    border-radius: 0.5rem !important;

    .icon-text-container {
      .button-icon {
        padding-bottom: 4px;
      }

      .button-text {
        margin-left: 12px;
        font-size: 13px;
      }
    }
  }
}
