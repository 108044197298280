.review-date-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.review-date-clear-icon {
  margin-left: -50px;
  cursor: pointer;
  z-index: 0;
}
