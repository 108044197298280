//injector:sass
@import '../common.scss';
@import 'flowingly.components/comments/add-comment.component.scss';
@import 'flowingly.components/comments/comment-history.component.scss';
@import 'flowingly.components/comments/comments.component.scss';
@import 'flowingly.components/customdatabase/customdatabase.config.component.scss';
@import 'flowingly.components/dialogwarningbox/dialog.warning.box.component.scss';
@import 'flowingly.components/flow.avatar.circle.component.scss';
@import 'flowingly.components/formula/formula.config.component.scss';
@import 'flowingly.components/lookup/lookup.config.component.scss';
@import 'flowingly.components/mention/flowingly.mention.scss';
@import 'flowingly.components/smartselect/smart.select.scss';
@import 'flowingly.components/tablebuilder/table.builder.component.scss';
@import 'flowingly.components/tablerunner/table.runner.component.scss';
@import 'flowingly.components/tableviewer/table.viewer.component.scss';
//endinjector

.cell-text,
.cell-numeric {
  margin-left: 12px;
}

.cell-text {
  textarea {
    resize: none;
    margin-top: 20px;
  }
}

.cell-numeric {
  input[type] {
    margin-top: 0;
  }
}

.icon-picker {
  height: 200px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  .icon-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40px, 8%));
    justify-content: center;
    font-size: 26px;
    column-gap: 10px;
    row-gap: 15px;
    overflow-y: auto;

    i {
      cursor: pointer;

      &:hover {
        color: $color-highlight-blue;
      }
    }
  }
}
