﻿@import '../../common.scss';

.round-badge {
  position: relative;
  display: inline-block;
  line-height: 45px;
}

.round-badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: 5px;
  right: -15px;
  font-size: 0.7em;
  background: #ff4500;
  color: white;
  width: 14px;
  height: 14px;
  text-align: center;
  line-height: 14px;
  border-radius: 50%;
}

.help-toolbar {
  color: $color-highlight-blue !important;

  a {
    color: $color-highlight-blue !important;
  }

  ul {
    background-color: white;

    a {
      color: $color-highlight-blue !important;
    }

    li {
      background-color: white;

      a {
        color: $color-highlight-blue !important;
      }

      a:hover {
        background-color: #ccc;
      }
    }
  }
}

.intercom-pipe {
  color: #35384d;
}
