@import '../../common.scss';

.desktop-banner {
  position: relative;
  top: 1px;
  text-align: center;
  line-height: 24px;

  span {
    display: inline-block;
    margin: 0;
  }
}

@media (max-width: 1439px) {
  .desktop-banner {
    display: none;
  }
}

.smaller-device-banner:hover {
  background-color: transparent !important;
  cursor: pointer;
  color: $color-highlight-blue;
}

@media (min-width: 1440px) {
  .smaller-device-banner {
    display: none;
  }
}

.smaller-device-banner-popup {
  position: absolute;
  background-color: #fafafa;
  width: 300px;
  border: 1px solid #e6e6e6;
  top: 58px;
  line-height: 20px;
  border-radius: 6px;
  cursor: default;
  color: #35384d;
  max-height: 75vh;
  overflow-y: auto;
  word-wrap: break-word;
  margin-right: 20px;
  text-align: center;
  padding: 10px;
  text-align: left;
}

.delegation-banner-popup-footer {
  background: #fff;
  text-align: center;
  padding-top: 10px;
  cursor: pointer;
}

.delegation-proper-fullnames {
  font-weight: bold;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  top: -1px;
}

.arrowup {
  position: absolute;
  top: 47px;
  width: 0;
  height: 5px;
  z-index: 1000 !important;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ddd;
}
.pointer {
  cursor: pointer;
}
