.flow-add-comment-container {
  width: 100%;
  margin-bottom: 20px;
}

.flow-add-comment-container-inner {
  border: solid 1px #e6e6e6;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }
}

.flow-add-comment-header {
  font-weight: 600;
  padding-bottom: 5px;
}

.flow-add-comment-action {
  display: flex;
  align-items: center;

  .add-comment-foot-text {
    flex: 1 1 auto;

    .add-comment-attach-file {
      display: flex;
      align-items: center;

      label {
        cursor: pointer;
        flex: 0 0 auto;
        margin-right: 5px;
        line-height: 34px;
      }

      ul {
        margin: 0;

        li {
          margin: 3px 0 3px 5px;
        }
      }
    }
  }
}

.flow-comments-container--mobile {
  .flow-add-comment-container-inner {
    margin: 5px 0 0 0;
  }
}

#addCommentFile {
  display: none;
}
