body {
  .pdf-export-container {
    background: white;
    position: absolute;
    right: 200%;
    /*right: 0px;*/
    top: 0;

    img.from-html-svg {
      margin-bottom: -6px;
    }
  }
}
