//injector:sass
@import '../common.scss';
@import './delegation-banner/delegation-banner.scss';
@import './grid/flowingly.grid.scss';
@import './intercom.message.launcher/intercom.message.launcher.scss';
@import './loading-text/loading.text.scss';
@import './user-notifications/user.notifications.scss';
@import './user-profile/user.profile.scss';
//endinjector

.flow-clipboard-copy {
  cursor: pointer;
  margin-left: 5px;
  position: relative;
  display: inline-block;

  div {
    position: absolute;
    background-color: #555;
    color: white;
    border-radius: 0.6em;
    font-style: normal;
    line-height: 1em;
    top: -28px;
    left: -20px;
    padding: 5px 8px;
    height: 1.8em;
  }
  div::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
}
