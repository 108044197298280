﻿@import '../../common.scss';

$color-border: #e6e6e6;

.mentions-container {
  border-radius: 4px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  margin-bottom: 0px;
  margin-top: 3px;
  background-color: #fff;
  max-height: 130px;
  overflow-y: auto;
}

.mention-item {
  padding: 10px;
  border: 1px solid $color-border;
  cursor: pointer;

  &:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
  }

  &.active {
    color: white;
    background-color: $color-border;
    border-color: $color-border;
  }

  &:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .avatar-container {
    float: left;
    width: 40px;
    height: 40px;

    .avatar {
      width: 40px;
      height: 40px;
      top: 0 !important;
      left: 0 !important;

      img,
      .circle {
        width: 40px;
        height: 40px;
      }

      .circle {
        padding: 0;
      }

      p {
        font-size: 20px;
        line-height: 40px;
      }
    }
  }

  .secondary-text {
    font-style: italic;
    margin-top: 10px;
  }
}

.mention-item__display-name {
  margin: 8px 5px 0 10px;
  color: $color-highlight-blue;
}

.mention-contents {
  height: 100px;
  margin-bottom: 5px;
  padding: 5px 10px;
  overflow: auto;
  border: solid 1px $color-border;
  border-radius: 4px;
  word-break: break-word;
  scrollbar-gutter: stable;
  text-align: justify;
  font {
    color: inherit;
  }
}

.mention-name {
  color: $color-highlight-blue;
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
  line-height: 18px;
}

.mention-contents-error {
  color: red;
}
