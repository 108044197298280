﻿.dialog-warning-box {
  border: 1px solid grey;
  padding: 20px 13px;

  legend {
    font-weight: 600;
    padding: 0 5px;
    font-size: 14px;
    margin-left: 15px;
  }

  .dialog-warning-content {
    ul {
      padding-left: 20px;
      margin: 0;
      position: relative;

      li + li {
        margin-top: 13px;
      }

      .warning-icon {
        position: absolute;
        color: #ff4500;
        left: 2px;
        line-height: 22px;
      }
    }
  }
}
